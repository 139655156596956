/* src/App.css */

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  padding: 50px;
}

.feed-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.feed-item {
  width: 300px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}

.feed-item h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.feed-item p {
  color: #666;
  font-size: 16px;
}

.feed-item a {
  display: inline-block;
  color: #0088cc;
  text-decoration: none;
  margin-top: 20px;
}

.feed-item a:hover {
  text-decoration: underline;
}